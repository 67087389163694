@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
--color-primary: #ff0000;
--color-secondary: #d4af28;
--color-textSecondary: #ff5b5c;
--color-tertiary: #f8b81f;
--color-dark: #111212;
--color-heading: #181818;
--dark-color-heading: #ffffff;
--color-border: #e5e5e5;
--dark-color-border: #282f3a;
--color-body: #808080;
--gradient-primary: linear-gradient(-90deg, #31b978 0%, #1ab69d 100%);
--bg-color: #EAF0F2;
--dark-bg-color: #020b17;
--cta-color-bg: #F0F4F5;
--color-bg-body: #ffffff;
--dark-color-bg-body: #111822;
--bg-color-2: #1c242f;
--color-white: #ffffff;
--color-black: #000000;
--footer-text: #181818;
--dark-footer-text: #808080;
--edu-btn-color: #ffffff;
--color-title: #bababa;
--color-gray: #afc8d1;
--color-gray-100: #f4f5f8;
--color-gray-200: #e3e7ed;
--color-gray-300: #cdd4e0;
--color-gray-400: #b4bdce;
--color-gray-500: #97a3b9;
--color-gray-600: #677793;
--color-gray-700: #596882;
--color-gray-800: #3b4863;
--color-gray-900: #1c273c;
--color-lighten01: #f0f4f5;
--color-lighten02: #edf5f8;
--color-lighten03: #f5f1eb;
--color-lighten04: #f7f5f2;
--color-extra01: #0ecd73;
--color-extra02: #8e56ff;
--color-extra03: #f92596;
--color-extra04: #5866eb;
--color-extra05: #f8941f;
--color-extra06: #39c0fa;
--color-extra07: #da04f8;
--color-extra08: #4664e4;
--color-extra09: #525151;
--color-extra10: #404040;
--color-extra11: #22272e;
--color-success: #3EB75E;
--color-danger: #FF0003;
--color-warning: #FF8F3C;
--color-info: #1BA2DB;
--color-placeholder: #808080;
--color-facebook: #1877f2;
--color-twitter: #1da1f2;
--color-youtube: #cd201f;
--color-linkedin: #0a66c2;
--color-pinterest: #E60022;
--color-instagram: #C231A1;
--color-vimeo: #00ADEF;
--color-twitch: #6441A3;
--color-discord: #7289da;
--border-width: 2px;
--radius-small: 4px;
--radius: 10px;
--radius-big: 16px;
--p-light: 300;
--p-regular: 400;
--p-medium: 500;
--p-semi-bold: 600;
--p-bold: 700;
--p-extra-bold: 800;
--shadow-darker: 0px 10px 20px 0px rgba(26, 46, 85, 0.1);
--shadow-darker2: 0px 20px 50px 0px rgba(26, 46, 85, 0.1);
--shadow-dark: 0px 10px 30px 0px rgba(20, 36, 66, 0.15);
--shadow-darkest: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
--shadow-darker3: 0px 4px 50px 0px rgba(0, 0, 0, 0.1);
--shadow-darker4: 0px 20px 70px 0px rgba(15, 107, 92, 0.2);
--transition: 0.3s;
--transition-2: 0.5s;
--transition-transform: transform .65s cubic-bezier(.23, 1, .32, 1);
--font-primary: 'Outfit', sans-serif;
--font-secondary: 'Spartan', sans-serif;
--font-icomoon: 'icomoon';
--font-size-b1: 15px;
--font-size-b2: 13px;
--line-height-b1: 1.73;
--line-height-b2: 1.85;
--h1: 50px;
--h2: 36px;
--h3: 28px;
--h4: 20px;
--h5: 18px;
--h6: 16px;
--h1-lineHeight: 1.2;
--h2-lineHeight: 1.39;
--h3-lineHeight: 1.43;
--h4-lineHeight: 1.4;
--h5-lineHeight: 1.45;
--h6-lineHeight: 1.62;
}
/*============================================================================*/
body {
font-family: var(--font-primary) !important;
font-size: 14px !important;
line-height: 24px !important;
}
a {
text-decoration: none !important;
color: var(--color-dark) !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
font-family: var(--font-secondary) !important;
font-weight: 700 !important;
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
box-shadow: none !important;
outline: transparent;
}
/*============================================================================*/
.topheader {
background: var(--color-dark);
width: 100%;
position: relative;
padding: 5px 0px;
color: var(--color-white);
}
.header-middle {
padding: 10px 0px;
width: 100%;
position: relative;
border-bottom: 1px solid var(--color-gray-100);
}
.fixed-head {
background: var(--color-white);
transform: translateY(40px);
/*transition: transform .3s;*/
top: -40px;
position: fixed;
left: 0;
z-index: 999;
}
.header {
width: 100%;
position: relative;
box-shadow: var(--shadow-darker);
background: var(--color-white);
}
.header .container,
.header .container-fluid,
.header-center,
.header-left,
.header-right {
align-items: center;
display: flex;
}
.header-left,
.header-right {
flex: 1 1;
}
.header-left .logo {
display: block;
margin-right: 75px;
max-width: 200px;
}
.header-left .logo img {
width: 75px;
}
.header-search {
flex: 1 1;
margin-right: 2rem;
position: relative;
width: 400px;
}
.header-search input {
padding: 10px 15px;
border-radius: 5px;
border: 1px solid var(--color-gray-200);
font-family: inherit;
background-color: var(--color-white);
color: #333;
flex: 1;
font-size: 14px;
letter-spacing: inherit;
height: 50px;
width: 100%;
}
.header-search input:focus {
background: var(--color-white);
outline: 0 none !important;
box-shadow: none !important;
border: 1px solid var(--color-gray-200);
}
.header-search .btn-search {
position: absolute;
background: transparent !important;
color: #333;
min-width: 48px;
height: 100%;
border-radius: 5px;
right: 0;
padding: 0;
border: 0;
}
.header-search .btn-search i {
margin: 5px;
vertical-align: middle;
font-size: 18px;
color: var(--color-primary);
}
.header-search .btn-search:hover {
background: transparent !important;
}
.header-right a {
display: flex;
align-items: center;
color: var(--color-dark);
}
.header-right .iconbox {
margin-right: 15px;
transition: none;
}
.header-right .iconbox .iconbox-icon {
font-size: 28px;
}
.navbar{
padding: 0px !important;
}
.nav-link{
padding: 10px 10px !important;
text-transform: uppercase;
font-weight: 600 !important;
}
.login-link {
margin-right: 15px;
margin-left: 15px;
}
.login-link i {
font-size: 28px;
}
.cart-toggle {
align-items: center;
margin-left: 15px;
position: relative;
}
.cart-toggle .cart-label {
display: flex;
flex-direction: column;
line-height: 20px;
margin-right: 10px;
}
.cart-toggle .cart-label .cart-name {
font-size: 12px;
font-weight: 400;
}
.cart-toggle .cart-label .cart-price {
font-size: 14px;
font-weight: 700;
font-family: var(--font-secondary) !important;
}
.cart-toggle i {
font-size: 28px;
}
.cart-toggle .cart-count {
position: absolute;
right: -8px;
top: 3px;
text-align: center;
border-radius: 50%;
background-color: var(--color-primary);
color: #fff;
z-index: 1;
font-size: 10px;
width: 18px;
height: 18px;
display: flex;
align-items: center;
justify-content: center;
font-weight: 600;
font-family: var(--font-secondary);
padding-top: 1px;
}
.cartheader {
padding: 5px 0;
}
.cartheader .logo img{
width: 60px;
}
.mheader {
background: var(--color-white);
box-shadow: var(--shadow-darker);
width: 100%;
position: fixed;
height: 60px;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 15px;
top: 0;
left: 0;
z-index: 9999;
}
.mheaderhome {
background: var(--color-white);
box-shadow: var(--shadow-darker);
width: 100%;
height: 110px;
padding: 0 15px;
z-index: 9999;
display: flex;
flex-direction: column;
justify-content: center;
}
.mheaderhome .mheaderhome-inner {
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 5px;
}
.mheader-search {
width: 100%;
position: relative;
}
.mheader-search input {
background: var(--color-white);
border: 0;
height: 50px;
padding: 0 30px;
width: 100%;
}
.mheader-search .mheader-search-close, .mheader-search .mheader-search-icon {
font-size: 18px;
position: absolute;
top: 12px;
}

.mheader-search .mheader-search-close {
right: 0;
}
.mheader-search .mheader-search-inner {
border: 1px solid #ddd;
display: flex;
align-items: center;
padding: 0px 15px;
font-size: 14px;
color: #666;
height: 45px;
border-radius: 5px;
}
.mheader-search .mheader-search-inner i {
margin-right: 10px;
}
.msearch-list {
width: 100%;
position: relative;
margin-top: 20px;
}
.msearch-list ul {
margin: 0;
padding: 0;
list-style: none;
}
.msearch-list ul li {
display: flex;
align-items: center;
height: 40px;
padding: 0px 15px;
border-bottom: 1px dotted var(--color-gray-200);
}
.sidenav-trigger {
font-size: 18px;
margin-right: 20px;
}
.mheader-left {
display: flex;
align-items: center;
font-size: 16px;
font-weight: 600;
}
.mheader-left .mlogo {
display: block;
}
.mheader-left .mlogo img {
width: 45px;
}
.msearch-link {
margin-right: 15px;
font-size: 20px;
}
.mlogin-link {
margin-right: 15px;
font-size: 20px;
}
.mcart-link {
position: relative;
font-size: 20px;
}
.mcart-link .cart-count {
position: absolute;
right: -10px;
top: -2px;
text-align: center;
border-radius: 50%;
background-color: var(--color-primary);
color: #fff;
z-index: 1;
font-size: 9px;
width: 18px;
height: 18px;
display: flex;
align-items: center;
justify-content: center;
font-weight: 600;
font-family: var(--font-secondary);
padding-top: 1px;
}
.header-search-list {
background-color: #fff;
border-radius: 6px;
box-shadow: 0 3px 10px rgb(0 0 0 / 19%);
overflow: auto;
position: absolute;
right: 0;
top: 52px;
-webkit-transition: height .15s ease-in-out;
transition: height .15s ease-in-out;
width: 100%;
z-index: 3;
padding: 10px 0px;
height: 275px;
overflow-y: auto;
overflow-x: hidden;
}
.header-search-list ul {
margin: 0;
padding: 0;
list-style: none;
}
.header-search-list ul li {
-webkit-box-pack: justify;
-ms-flex-pack: justify;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
color: #2E054E;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
font-size: 14px;
justify-content: space-between;
padding: 6px 12px;
text-decoration: none;
cursor: pointer;
}
/*============================================================================*/
.tx-theme {
color: var(--color-secondary);
}
.tx-green {
color: var(--color-extra01);
}
.tx-10 {
font-size: 10px;
}
.tx-11 {
font-size: 11px;
}
.tx-12 {
font-size: 12px;
}
.tx-13 {
font-size: 13px;
}
.tx-14 {
font-size: 14px;
}
.tx-15 {
font-size: 15px;
}
.tx-18 {
font-size: 18px;
}
.tx-22 {
font-size: 22px;
}
.fw600 {
font-weight: 600;
}
.secsmall {
padding: 30px 0px;
}
.secmedium {
padding: 60px 0px;
}
.seclarge {
padding: 80px 0px;
}
.divider {
display: inline-block;
vertical-align: middle;
height: 2.5rem;
width: 1px;
background-color: #e1e1e1;
}
.poniter {
cursor: pointer;
}
.btn-small {
font-size: 12px !important;
padding: 5px 7px !important
}
.btn-medium {
font-size: 14px !important;
padding: 10px 30px !important;
}
.btn-large {
font-size: 16px !important;
padding: 15px 30px !important;
}
.btn-full {
width: 100%;
}
.btn-primary {
background: var(--color-secondary) !important;
border-color: var(--color-secondary) !important;
}
.btn-primary-outline {
background: transparent !important;
border-color: var(--color-secondary) !important;
color: var(--color-secondary) !important;
}
@media only screen and (max-width: 968px) {
.secsmall {
padding: 20px 0px;
}
}
/*============================================================================*/
.sliderimg {
width: 100%;
position: relative;
}
.sliderimg img {
width: 100%;
border-radius: 10px;
height: 400px;
object-fit: cover;
}
/*============================================================================*/
.catbox {
width: 100%;
position: relative;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
margin-bottom: 10px;
color: var(--color-dark);
}
.catbox .catbox-thumbnail {
width: 100%;
position: relative;
overflow: hidden;
}
.catbox img {
width: 100%;
height: 170px;
border-radius: 10px;
object-fit: cover;
transition: var(--transition);
}
.catbox img:hover {
-webkit-transform: scale(1.1);
-ms-transform: scale(1.1);
transform: scale(1.1);
}
.catbox .catbox-name {
font-size: 16px;
font-weight: 500;
margin-top: 10px;
}
@media only screen and (max-width: 968px) {
.catbox {
margin-bottom: 0px;
}
.catbox img {
height: 110px;
}
.catbox .catbox-name {
font-size: 13px;
font-weight: 500;
margin-top: 5px;
}
}
/*============================================================================*/
.form-group {
width: 100%;
position: relative;
}
.form-group label {
margin-bottom: 5px;
}
.form-group input {
width: 100%;
position: relative;
border: 1px solid var(--color-gray-200);
height: 50px;
border-radius: 5px;
padding: 15px;
}
.has-error input{
border: 1px solid var(--color-primary) !important;
}
.passwordshow {
position: absolute;
top: 0;
right: 0;
width: 40px;
line-height: 50px;
text-align: center;
}
.country-input input {
padding-left: 80px;
}
.country-input .country-code {
position: absolute;
top: 0;
height: 50px;
display: flex;
align-items: center;
min-width: 65px;
justify-content: center;
border-right: 1px solid var(--color-gray-200);
display: flex;
justify-content: space-around;
padding: 0px 5px;
}
.country-code::after {
content: "";
font-family: "riode" !important;
speak: never;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
font-size: 10px;
}
/*============================================================================*/
.noimg{
padding: 25px;
width: 100%;
position: relative;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
text-align: center;
}
.noimg img{
width: 250px;
}
/*============================================================================*/
.subheader {
background: var(--color-gray-100);
padding: 20px 0px;
}

.subheader.subheader-small {
padding: 20px 0px 10px;
}

.subheader h1 {
font-size: 24px;
}

.subheader .breadcrumb {
margin-bottom: 0px;
}

.breadcrumb-inner .breadcrumb {
margin: 0;
}

/*============================================================================*/
.account-user {
background: var(--color-white);
box-shadow: rgba(33, 33, 33, .06) 0 4px 24px 5px;
width: 100%;
position: relative;
border-radius: 10px;
padding: 15px;
display: flex;
align-items: center;
}

.account-user .au-imag {
width: 50px;
height: 50px;
background: var(--color-gray);
border-radius: 50%;
}

.account-user .au-imag img {
width: 50px;
height: 50px;
border-radius: 50%;
object-fit: cover;
}

.account-user .au-content {
flex: 1;
margin-left: 15px;
}

.aclist {
background: var(--color-white);
box-shadow: rgba(33, 33, 33, .06) 0 4px 24px 5px;
width: 100%;
position: relative;
border-radius: 10px;
padding: 15px 20px;
}

.aclist ul {
margin: 0;
padding: 0;
list-style: none;
}

.aclist ul li {
display: inline-block;
width: 100%;
font-weight: 600;
}

.aclist ul li a {
display: flex;
align-items: center;
padding: 7px 0px;
justify-content: space-between;
}

.aclist ul li.active a {
color: var(--color-secondary) !important;
}

.acpanel {
background: var(--color-white);
box-shadow: rgba(33, 33, 33, .06) 0 4px 24px 5px;
width: 100%;
position: relative;
border-radius: 10px;
}

.acpanel .acpanel-header {
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
padding: 20px;
border-bottom: 1px solid #eee;
}

.acpanel .acpanel-header h4 {
margin-bottom: 0px;
font-size: 20px;
}

.acpanel .acpanel-body {
padding: 20px;
width: 100%;
position: relative;
}

.aobox {
width: 100%;
height: 150px;
background: var(--color-gray-100);
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
text-align: center;
padding: 0px 35px;
}

.aobox h6 {
margin-bottom: 0px;
font-size: 14px;
}

.aobox img {
width: 32px;
}

.macc-profile {
background: var(--color-white);
width: 100%;
position: relative;
padding: 15px;
display: flex;
align-items: center;
border-bottom: 2px solid var(--color-gray-200);
}
.macc-profile .macc-img {
width: 50px;
height: 50px;
background: var(--color-white);
border-radius: 50%;
}
.macc-profile .macc-img img {
width: 50px;
height: 50px;
border-radius: 50%;
object-fit: cover;
}
.macc-profile .macc-content {
flex: 1;
margin-left: 15px;
line-height: 20px;
}
.macc-list {
width: 100%;
position: relative;
background: var(--color-white);
border-bottom: 2px solid var(--color-gray-200);
}
.macc-list ul {
margin: 0;
padding: 0;
list-style: none;
}
.macc-list ul li {
display: flex;
align-items: center;
font-size: 14px;
font-weight: 600;
height: 60px;
padding: 0px 15px;
border-bottom: 1px solid var(--color-gray-200);
line-height: 20px;
background: var(--color-white);
}
.macc-list ul li a {
display: flex;
align-items: center;
width: 100%;
}
.macc-list .macc-list-icon {
width: 36px;
height: 36px;
background: var(--color-gray-200);
margin-right: 15px;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
}
.macc-list .macc-list-icon img {
width: 18px;
height: 18px;
}
.macc-list i {
margin-left: auto;
font-size: 18px;
}
.logout-button {
background: var(--color-white);
padding: 20px;
text-align: center;
color: #fff;
}
.logout-logo {
width: 120px;
}
.helpbox {
width: 100%;
position: relative;
border-bottom: 3px solid var(--color-gray-100);
}

/*============================================================================*/
.page-title {
width: 100%;
position: relative;
}

.page-title h1 {
font-size: 18px;
}

.shortby {
width: 100%;
position: relative;
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 20px;
}

.shortby select {
border: 1px solid #ddd;
background: #fff;
padding: 10px;
border-radius: 5px;
font-size: 12px;
}

/*===========================================================================*/
.appBottomMenu {
position: fixed;
z-index: 999;
bottom: 0;
left: 0;
right: 0;
background: var(--color-white);
display: flex;
align-items: center;
justify-content: center;
min-height: 56px;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.appBottomMenu .item {
font-size: 12px;
letter-spacing: 0;
text-align: center;
width: 100%;
height: 56px;
line-height: 1.2em;
display: flex;
align-items: center;
justify-content: center;
position: relative;
}

.appBottomMenu .item .col {
width: 100%;
padding: 0 4px;
text-align: center;
}

.appBottomMenu .item i {
display: inline-flex;
margin: 1px auto 3px auto;
font-size: 20px;
line-height: 1em;
transition: none;
display: block;
margin-top: 1px;
margin-bottom: 3px;
}

.appBottomMenu .item.active:before {
background: transparent;
}

.appBottomMenu .item.active {
color: var(--color-primary) !important;
}

.appBottomMenu .item.active i {
color: var(--color-primary) !important;
font-weight: 500;
}

/*============================================================================*/
.product {
position: relative;
overflow: hidden;
color: #999;
transition: 0.3s;
}

.product figure {
margin: 0;
}

.product-media {
position: relative;
margin-bottom: 0;
transition: box-shadow 0.3s;
}

.product-media img {
display: block;
width: 100%;
height: auto;
transition: all 0.5s;
}

.product-media img:first-child {
position: relative;
opacity: 1;
}

.product-details {
position: relative;
background-color: #fff;
padding-top: 15px;
padding-bottom: 0px;
}
.product-cat {
margin-bottom: 10px;
font-size: 11px;
font-weight: 400;
line-height: 1;
text-transform: capitalize;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
}
.product-name {
margin-bottom: 0.3rem;
font-family: inherit;
font-size: 14px;
font-weight: 400;
letter-spacing: -0.01em;
color: #333;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}

.product-price {
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
display: block;
align-items: center;
flex-wrap: wrap;
margin-bottom: 5px;
font-size: 18px;
font-weight: 600;
line-height: 1.86;
color: #222;
}

.product-price .new-price {
margin-right: 1rem;
text-decoration: none;
}

.product-price .old-price {
color: #aaa;
}

.ratings-container {
display: flex;
align-items: center;
margin-left: 1px;
line-height: 1.1;
margin-bottom: 1.2rem;
font-size: 11px;
letter-spacing: 0.2em;
}

.ratings,
.ratings-full {
position: relative;
font-family: "riode";
}

.ratings-full::before {
content: "" "" "" "" "";
color: rgba(0, 0, 0, 0.16);
font-family: "riode";
}

.ratings::before {
content: "" "" "" "" "";
color: #d26e4b;
font-family: "riode";
}
.ratings {
position: absolute;
top: 0;
left: 0;
white-space: nowrap;
overflow: hidden;
}
.product:hover .product-action,
.product:hover .product-action-vertical {
visibility: visible;
opacity: 1
}

.product-media:hover img:first-child {
opacity: 0
}

.product-media:hover img:last-child {
opacity: 1
}

.product-action {
position: absolute;
left: 0;
right: 0;
bottom: 0;
display: flex;
align-items: center;
background-color: transparent;
z-index: 10;
transition: opacity 0.3s, visibility 0.3s;
opacity: 0
}

.btn-product {
background-color: var(--color-secondary);
flex: 1 1;
font-size: 14px;
font-weight: 500;
justify-content: center;
line-height: 1.2;
padding: 1.14em 0;
text-transform: uppercase;
transition: opacity .3s;
display: flex;
color: var(--color-white) !important;
}

.product-label-group {
display: flex;
flex-direction: column;
position: absolute;
left: 10px;
top: 10px;
max-width: 10rem;
color: var(--color-white);
justify-content: center;
}

.product-label {
display: inline-block;
text-align: center;
margin-bottom: 5px;
padding: 5px 10px;
font-size: 11px;
border-radius: 3px;
line-height: 1;
color: #fff;
text-transform: uppercase;
}

.product-label.label-new {
background: var(--color-secondary);
}

.product-label.label-sale {
background: var(--color-primary);
}

/*============================================================================*/
.product-single .product-details {
padding: 0 0 3rem;
padding-left: 0px;
background-color: transparent;
}

.product-single .product-name {
font-size: 24px;
margin-bottom: 10px;
}

.product-single .product-price {
display: block;
margin-bottom: 20px;
color: var(--color-secondary);
font-size: 24px;
font-weight: 700;
letter-spacing: -0.025em;
line-height: 1
}

.product-single .old-price {
font-weight: 600
}

.product-single .rating-reviews {
font-size: 12px;
margin-left: 10px;
letter-spacing: 0px;
}

.product-single .rating-reviews:not(:hover) {
color: #999
}

.product-single .ratings-container {
margin-bottom: 15px;
font-size: 14px
}

.product-single .ratings-container .ratings:before {
color: #666
}

.product-single .ratings-full {
margin-top: -2px
}

.product-single-media img {
width: 100%;
}

.product-short-desc {
font-size: 16px;
}

.product-meta {
margin-bottom: 10px;
color: #999;
font-size: 14px;
}

.product-single hr {
border-top: 1px solid var(--color-gray-400) !important;
}

.product-single .product-button {
align-items: center;
display: flex;
}

.pbqty {
border: 1px solid var(--color-secondary);
border-radius: 5px;
display: inline-flex;
height: 50px;
justify-content: space-between;
margin-right: 10px;
width: 120px;
}

.pbqty button {
-webkit-appearance: none;
background-color: initial;
border: 1px solid transparent;
color: var(--color-secondary);
cursor: pointer;
font-size: 16px;
min-width: 30px !important;
padding: 0;
width: 50px !important;
}

.pbqty input {
background: #fff;
background: var(--color-white);
border: 1px solid #fff;
border: 1px solid var(--color-white);
color: #000;
color: var(--color-black);
height: 48px;
text-align: center;
width: 100%;
}

.pbqty .quantity {
-moz-appearance: textfield;
max-width: 48px;
}

.product-button button {
border-radius: 5px;
font-size: 15px;
font-weight: 600;
height: 50px;
justify-content: center;
min-width: 200px;
}

.product-single button i {
margin-right: 7px;
}

/*============================================================================*/
.gallery-img {
width: 100%;
position: relative;
}

.gallery-img img {
width: 100%;
}

.product-single .mproduct-details {
position: relative;
width: 100%;
padding: 15px;
border-bottom: 3px solid var(--color-gray-200);
}
.mproduct-details .product-name {
font-size: 14px;
font-weight: 700 !important;
margin-bottom: 10px;
white-space: normal;
}
.mproduct-details .product-price {
font-size: 18px;
margin-bottom: 15px;
}
.mproduct-details .product-price .new-price {
margin-right: 7px;
text-decoration: none;
}

.mproduct-details .product-price .old-price {
color: #aaa;
margin-right: 7px;
font-size: 16px;
}
.mproduct-details .ratings-container {
margin-bottom: 0px;
}
.mproductBox {
padding: 15px;
width: 100%;
position: relative;
}
.mproduct-footer {
width: 100%;
position: fixed;
bottom: 0;
padding: 15px;
display: flex;
align-items: center;
background: var(--color-white);
border-top: 1px solid var(--color-gray-100);
z-index: 999;
}

.mproduct-footer button {
width: 100%;
display: flex;
align-items: center;
justify-content: center;
border-radius: 5px;
font-size: 15px;
font-weight: 600;
height: 50px;
justify-content: center;
}

/*============================================================================*/
.poptionlist {
width: 100%;
position: relative;
margin-bottom: 10px;
}
.poptionlist .poptionlist-title {
font-size: 14px;
margin-bottom: 10px;
}
.poptionlist ul {
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
flex-wrap: wrap;
}
.poptionlist li {
display: inline-block;
border: 1px solid var(--color-gray-300);
padding: 7px 10px;
border-radius: 5px;
width: 175px;
margin-right: 10px;
margin-bottom: 10px;
}
.poptionlist li.active {
border: 1px solid var(--color-secondary);
background: #fffdf5;
}
.poptionlist-details {
display: flex;
align-items: center;
}
.poptionlist-details .poptionlist-img {
width: 40px;
height: 40px;
margin-right: 10px;
}
.poptionlist-details .poptionlist-img img {
width: 40px;
height: 40px;
object-fit: cover;
border-radius: 5px;
}
.poptionlist-details .poptionlist-text {
flex: 1 1;
line-height: 20px;
}
.poptionlist-second li {
width: auto;
padding: 4px 7px;
}
.poptionlist-third{
width: 100%;
position: relative;
margin-bottom: 10px;
}
.poptionlist-third .poptionlist-title {
font-size: 14px;
margin-bottom: 10px;
}
.poptionlist-third ul {
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
flex-wrap: wrap;
}
.poptionlist-third li {
display: inline-block;
border: 1px solid var(--color-gray-300);
padding: 7px 10px;
border-radius: 5px;
width: 100%;
margin-right: 10px;
margin-bottom: 10px;
}
.poptionlist-third li.active {
border: 1px solid var(--color-secondary);
background: #fffdf5;
}
.poptionlist-details-third {
display: flex;
align-items: center;
}
.poptionlist-details-third .poptionlist-img {
width: 40px;
height: 40px;
margin-right: 10px;
}
.poptionlist-details-third .poptionlist-img img {
width: 40px;
height: 40px;
object-fit: cover;
border-radius: 5px;
}
.poptionlist-details-third .poptionlist-text {
flex: 1 1;
line-height: 16px;
}
@media only screen and (max-width: 968px) {
.poptionlist li {
display: flex;
border: 1px solid var(--color-gray-300);
padding: 0px 10px;
border-radius: 5px;
width: 47%;
margin-right: 10px;
margin-bottom: 10px;
height: 45px;
align-items: center;
}
.poptionlist-details .poptionlist-img {
width: 24px;
height: 24px;
margin-right: 10px;
}
.poptionlist-details .poptionlist-img img {
width: 24px;
height: 24px;
object-fit: cover;
border-radius: 5px;
}
.poptionlist-details .poptionlist-text {
flex: 1 1;
line-height: 16px;
font-size: 12px;
}
}
/*============================================================================*/
.filterbox {
background: #fff;
background: var(--color-white);
border-radius: 10px;
box-shadow: 0 4px 24px 5px rgba(33, 33, 33, .06);
position: relative;
width: 100%;
}

.filterbox .filterbox-title {
font-size: 16px;
font-weight: 600;
padding: 15px 20px;
border-bottom: 1px dotted #ddd;
}

.filterbox .filterbox-body {
padding: 20px;
}

.container-checkbox {
display: flex;
align-items: center;
position: relative;
cursor: pointer;
font-size: 14px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
flex-direction: row-reverse;
justify-content: flex-end;
padding: 4px 0px;
}

.sflist {
width: 100%;
position: relative;
}

.sflist ul {
margin: 0;
padding: 0;
list-style: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
position: absolute;
opacity: 0;
cursor: pointer;
}

.container-checkbox .checkmark {
position: relative;
top: 0;
left: 0;
height: 18px;
width: 18px;
background-color: #fff;
border: 1px solid #999;
border-radius: 3px;
margin-right: 10px;
}

.container-checkbox:hover input~.checkmark {
background-color: #ccc;
}

.container-checkbox input:checked~.checkmark {
background-color: #fff;
}

.container-checkbox .checkmark:after {
content: "";
position: absolute;
display: none;
}

.container-checkbox input:checked~.checkmark:after {
display: block;
}

.container-checkbox .checkmark:after {
left: 6.5px;
top: 3px;
width: 4px;
height: 9px;
border: solid var(--color-primary);
border-width: 0 2px 2px 0;
-webkit-transform: rotate(45deg);
-ms-transform: rotate(45deg);
transform: rotate(45deg);
}

.container-radio {
display: block;
position: relative;
padding-left: 35px;
margin-bottom: 12px;
cursor: pointer;
font-size: 22px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.container-radio input {
position: absolute;
opacity: 0;
cursor: pointer;
}

.container-radio .checkmark {
position: absolute;
top: 0;
left: 0;
height: 25px;
width: 25px;
background-color: #eee;
border-radius: 50%;
}

.container-radio:hover input~.checkmark {
background-color: #ccc;
}

.container-radio input:checked~.checkmark {
background-color: #fff;
}

.container-radio .checkmark:after {
content: "";
position: absolute;
display: none;
}

.container-radio input:checked~.checkmark:after {
display: block;
}

.container-radio .checkmark:after {
top: 9px;
left: 9px;
width: 8px;
height: 8px;
border-radius: 50%;
background: white;
}
@media only screen and (max-width: 968px) {
.container-checkbox {
display: flex;
align-items: center;
position: relative;
cursor: pointer;
font-size: 14px;
-webkit-user-select: none;
user-select: none;
flex-direction: row-reverse;
justify-content: flex-end;
padding: 4px 0px;
line-height: 16px;
}
}
/*============================================================================*/
.mwapper {
margin-top: 60px;
}

.mwapperHome {
margin-top: 70px;
}

.menuname {
width: 100%;
position: relative;
padding: 10px 15px;
font-size: 16px;
font-weight: 600;
background: var(--color-gray-100);
}

.hproduct {
width: 100%;
position: relative;
padding: 15px;
background: var(--color-white);
border-bottom: 4px solid var(--color-gray-100);
}

.hproduct .hproduct-name {
font-size: 14px;
}

.hproduct figure {
margin: 0;
}

.hproduct-media {
position: relative;
margin-bottom: 0;
transition: box-shadow 0.3s;
}

.hproduct-media img {
display: block;
width: 100%;
height: auto;
transition: all 0.5s;
border-radius: 10px;
}

.hproduct-price {
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
display: block;
align-items: center;
flex-wrap: wrap;
margin-bottom: 5px;
font-size: 18px;
font-weight: 600;
line-height: 1.86;
color: #222;
}

.hproduct-price .new-price {
margin-right: 1rem;
text-decoration: none;
}

.hproduct-price .old-price {
color: #aaa;
}

/*============================================================================*/
/*============================================================================*/
/*============================================================================*/
/*============================================================================*/
/*============================================================================*/
/*============================================================================*/
/*============================================================================*/
/*============================================================================*/
/*============================================================================*/
/*==================================================*/
/*==================================================*/
/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal {
z-index: 9999 !important;
}
.modal.left .modal-dialog,
.modal.right .modal-dialog {
position: fixed;
margin: auto;
width: 350px;
height: 100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}
.modal.left .modal-content,
.modal.right .modal-content {
height: 100%;
overflow-y: auto;
}
.modal.left .modal-body,
.modal.right .modal-body {
padding: 15px 15px 80px;
}
.cart-modal.modal.fade .modal-dialog {
transition: transform .3s ease-out;
transform: translate(0, 0px) !important;
}
/*Left*/
.modal.left.fade .modal-dialog {
left: -320px;
-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
-o-transition: opacity 0.3s linear, left 0.3s ease-out;
transition: opacity 0.3s linear, left 0.3s ease-out;
}
.modal.left.fade.show .modal-dialog {
left: 0;
}
/*Right*/
.modal.right.fade .modal-dialog {
right: -350px;
-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
-o-transition: opacity 0.3s linear, right 0.3s ease-out;
transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.right.fade.show .modal-dialog {
right: 0;
width: 400px;
}
.cart-modal .modal-content {
background: var(--color-white);
background: var(--color-white);
border: 0;
border-radius: 0;
}
@media only screen and (max-width: 968px) {
.modal.right.fade.show .modal-dialog {
right: 0;
width: 100%;
}
}
/*============================================================================*/
.cartmodalcontent {
width: 100%;
position: relative;
}
.cartmodalcontent .cartmodalcontent-header {
padding: 18px;
display: flex;
-moz-box-pack: justify;
justify-content: space-between;
-moz-box-align: center;
align-items: center;
background: var(--color-gray-100);
}
.cartmodalcontent-close {
font-size: 18px;
color: var(--color-black);
}
.cartmodalcontent-middle {
max-height: 360px;
overflow-x: hidden;
padding-bottom: 100px;
width: 100%;
}
.cartmodalcontent-footer {
background: var(--color-white);
bottom: 0;
left: 0;
padding: 15px 25px 25px;
position: fixed;
width: 100%;
}
.cartmodalcontent-total {
align-items: center;
border-bottom: 1px solid #f5f5f5;
border-bottom: 1px solid var(--color-gray-200);
border-top: 1px solid #f5f5f5;
border-top: 1px solid var(--color-gray-200);
display: flex;
justify-content: space-between;
margin-bottom: 20px;
padding: 10px 0;
}
.cartmodalcontent-action {
position: relative;
text-align: center;
width: 100%;
}
.cartbox-action-btn {
border-bottom: 2px solid #cf145a;
border-bottom: 2px solid var(--color-primary);
display: inline-block;
margin-bottom: 20px;
}
.productCart {
align-items: center;
display: flex;
padding: 15px;
position: relative;
}
.productCart .productCart-media {
margin-right: 10px;
position: static;
width: 70px;
overflow: hidden;
height: 70px;
margin-right: 15px;
}
.productCart .productCart-media img {
width: 70px;
height: 70px;
border-radius: 5px;
object-fit: cover;
}
.productCart .productCart-details {
flex: 1;
}
.productCart .productCart-name {
font-size: 12px;
margin-bottom: 5px;
}
.productCart .productCart-price {
align-items: center;
display: flex;
font-size: 14px;
}
.productCart .productCart-quantity {
align-items: center;
display: flex;
font-weight: 400;
margin-right: .5rem;
}
.productCart .productCart-quantity::after {
content: "X";
font-size: 13px;
line-height: 0;
margin-left: .5rem;
text-transform: none;
}
.productCart .new-price {
margin-right: 1rem;
text-decoration: none;
}
.productCart-remove {
align-items: center;
border-radius: 50%;
display: flex;
font-size: 8px;
height: 16px;
justify-content: center;
position: absolute;
right: 25px;
top: 15px;
width: 16px;
}
.productCart-remove i {
font-size: 12px;
}
/*============================================================================*/
.lrmodal-content {
padding: 25px;
width: 100%;
position: relative;
}
.lrmodal .modal-dialog {
max-width: 400px;
}
.lrmodal .pop-close {
background: transparent;
font-size: 20px;
top: 10px;
}
.pop-close {
background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
border: 0;
border-radius: .25rem;
box-sizing: initial;
color: #000;
cursor: pointer;
height: 1em;
opacity: .5;
padding: .25em;
position: absolute;
right: 15px;
top: 15px;
width: 1em;
z-index: 9999;
}
@media (max-width: 991px) {
.lrmodal .modal-dialog {
height: 100%;
margin: 0;
max-width: 100%;
}
.lrmodal .modal-content {
border-radius: 0;
height: 100%;
border: 0px !important;
}
}
/*============================================================================*/
.chooseAddressModal{
width: 100%;
position: relative;
}
.chooseAddressModal .closeModal {

position: absolute;
top: 10px;
right: 10px;
width: 25px;
height: 25px;
border-radius: 50%;
background: var(--color-black);
border: 0px;
z-index: 9999;
display: flex;
align-items: center;
justify-content: center;
}
.chooseAddressModal .closeModal i{
color: var(--color-white);
}
.chooseAddressModal .addressmap{
width: 100%;
position: relative;
height: 200px;
}
.chooseAddressModal .adform{
padding: 25px;
width: 100%;
position: relative;
}
.adform .adformGroup{
width: 100%;
position: relative;
}
.adform input{
width: 100%;
height: 50px;
border: 1px solid var(--color-gray-200);
padding: 15px;
border-radius: 5px;
}
/*============================================================================*/
.footer {
background: var(--color-gray-100);
padding: 60px 0px;
width: 100%;
position: relative;
}
@media only screen and (max-width: 968px) {
.footer {
background: var(--color-gray-100);
padding: 60px 0px 100px;
width: 100%;
position: relative;
}  
}
.footer-title {
font-size: 18px;
}

.footer-list {
width: 100%;
position: relative;
}

.footer-list ul {
margin: 0;
padding: 0;
list-style: none;
}

.footer-list ul li {
display: inline-block;
width: 100%;
position: relative;
}

.footer-list ul li a {
display: inline-block;
line-height: 32px;
color: var(--color-dark);
transition: all 0.3s ease;
}

.footer-list ul li a span {
margin: 0;
padding: 0;
-webkit-transition: all 0.3s ease;
-ms-transition: all 0.3s ease;
transition: all 0.3s ease;
}

.footer-list ul li a:hover {
color: var(--color-dark);
}

.footer-list ul li a:hover span {
margin-left: 0px
}

.footer-list ul li a span:after {
content: "";
display: block;
position: relative;
z-index: 1;
top: auto;
bottom: 5px;
left: 0;
-webkit-transform: none;
-ms-transform: none;
transform: none;
width: 0;
height: 1px;
-webkit-transition: all 0.3s ease;
-ms-transition: all 0.3s ease;
transition: all 0.3s ease;
background-color: var(--color-primary)
}

.footer-list ul li a:hover span:after {
width: 100%
}

.footer-logo {
width: 100px;
}

.copyright {
width: 100%;
position: relative;
padding: 10px 0px;
}

.footer-contact {
width: 100%;
position: relative;
}

.footer-contact ul {
margin: 0;
padding: 0;
list-style: none;
}

.footer-contact ul li {
width: 100%;
display: inline-block;
position: relative;
margin-top: 10px;
}

.footer-contact ul li a {
width: 100%;
display: flex;
align-items: center;
line-height: 24px;
}

.footer-contact ul li a span {
flex: 1;
margin-left: 10px;
}

.footer-contact ul li i {
position: relative;
font-size: 14px;
border: 1px solid var(--color-primary);
width: 30px;
height: 30px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
border-radius: 50%;
background: var(--color-primary);
color: #fff;
}

.footer-contact ul li a:hover {
color: var(--color-primary) !important;
}

.subscribe-form {
position: relative;
width: 100%;
}

.subscribe-form input {
height: 47px;
background: #fff;
border: 0;
color: #b1b1b1;
padding: 10px 120px 10px 20px;
display: inline-block;
width: 100%;
font-size: 14px;
border: none;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
-ms-border-radius: 5px;
-o-border-radius: 5px;
border-radius: 5px;
outline: none;
}

.subscribe-button input.button {
position: absolute;
top: 0;
right: 0;
text-transform: capitalize;
font-weight: 400;
font-size: 14px;
color: #fff;
background: var(--color-secondary);
border: 0;
box-shadow: none;
padding: 0 20px;
line-height: 47px;
height: 47px;
border-radius: 0 5px 5px 0;
-webkit-transition: all 300ms linear;
-moz-transition: all 300ms linear;
-ms-transition: all 300ms linear;
-o-transition: all 300ms linear;
transition: all 300ms linear;
width: 90px;
outline: none;
}

.subscribe-button input.button:hover {
background: var(--color-primary);
}

.footer-social {
width: 100%;
position: relative;
margin-top: 10px;
}

.footer-social ul {
margin: 0;
padding: 0;
list-style: none;
}

.footer-social ul li {
display: inline-block;
margin: 0px 10px 0px 0px;
}

.footer-social ul li a {
width: 30px;
height: 30px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
border: 1px solid #ddd;
border-radius: 5px;
-webkit-transition: border-top-left-radius .1s linear 0s, border-top-right-radius .1s linear .1s, border-bottom-right-radius .1s linear .2s, border-bottom-left-radius .1s linear .3s;
-o-transition: border-top-left-radius .1s linear 0s, border-top-right-radius .1s linear .1s, border-bottom-right-radius .1s linear .2s, border-bottom-left-radius .1s linear .3s;
transition: border-top-left-radius .1s linear 0s, border-top-right-radius .1s linear .1s, border-bottom-right-radius .1s linear .2s, border-bottom-left-radius .1s linear .3s
}

.footer-social ul li a i {
font-size: 14px;
margin-top: 3px;
}

.footer-social li:hover a {
border-radius: 2px;
background: #4c6fa4;
}

/*============================================================================*/
@font-face {
font-family: "riode";
src: url("../public/fonts/riode.eot?5gap68");
src: url("../public/fonts/riode.eot?5gap68#iefix") format("embedded-opentype"),
url("../public/fonts/riode.ttf?5gap68") format("truetype"),
url("../public/fonts/riode.woff?5gap68") format("woff");
font-weight: normal;
font-style: normal;
font-display: block
}

[class*=" d-icon-"],
[class^=d-icon-] {
display: inline-block;
line-height: 1
}

[class*=" d-icon-"]:before,
[class^=d-icon-]:before {
font-family: "riode" !important;
speak: never;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale
}

.d-icon-truck:before {
content: ""
}

.d-icon-service:before {
content: ""
}

.d-icon-secure:before {
content: ""
}

.d-icon-lock:before {
content: ""
}

.d-icon-percent:before {
content: ""
}

.d-icon-layer:before {
content: ""
}

.d-icon-alert:before {
content: ""
}

.d-icon-database:before {
content: ""
}

.d-icon-money:before {
content: ""
}

.d-icon-shoppingbag:before {
content: ""
}

.d-icon-t-shirt1:before {
content: ""
}

.d-icon-t-shirt2:before {
content: ""
}

.d-icon-hat:before {
content: ""
}

.d-icon-officebag:before {
content: ""
}

.d-icon-handbag:before {
content: ""
}

.d-icon-backpack:before {
content: ""
}

.d-icon-shoes:before {
content: ""
}

.d-icon-pillow:before {
content: ""
}

.d-icon-dress:before {
content: ""
}

.d-icon-loungewear:before {
content: ""
}

.d-icon-desktop:before {
content: ""
}

.d-icon-laptop:before {
content: ""
}

.d-icon-gamepad1:before {
content: ""
}

.d-icon-wireless:before {
content: ""
}

.d-icon-projector:before {
content: ""
}

.d-icon-drone1:before {
content: ""
}

.d-icon-drone2:before {
content: ""
}

.d-icon-radio:before {
content: ""
}

.d-icon-battery:before {
content: ""
}

.d-icon-plugin:before {
content: ""
}

.d-icon-memory:before {
content: ""
}

.d-icon-ball:before {
content: ""
}

.d-icon-basketball1:before {
content: ""
}

.d-icon-babycare:before {
content: ""
}

.d-icon-card:before {
content: ""
}

.d-icon-gamepad2:before {
content: ""
}

.d-icon-camera1:before {
content: ""
}

.d-icon-camera2:before {
content: ""
}

.d-icon-babywear:before {
content: ""
}

.d-icon-abacus:before {
content: ""
}

.d-icon-pot:before {
content: ""
}

.d-icon-freezer:before {
content: ""
}

.d-icon-cook:before {
content: ""
}

.d-icon-student:before {
content: ""
}

.d-icon-category:before {
content: ""
}

.d-icon-basketball2:before {
content: ""
}

.d-icon-watch:before {
content: ""
}

.d-icon-tcard:before {
content: ""
}

.d-icon-heartbeat:before {
content: ""
}

.d-icon-watch-round:before {
content: ""
}

.d-icon-washbowl:before {
content: ""
}

.d-icon-bridge-lamp:before {
content: ""
}

.d-icon-lamp:before {
content: ""
}

.d-icon-sofa:before {
content: ""
}

.d-icon-sofa2:before {
content: ""
}

.d-icon-bed:before {
content: ""
}

.d-icon-table:before {
content: ""
}

.d-icon-table-lamp:before {
content: ""
}

.d-icon-table-tv:before {
content: ""
}

.d-icon-mirror:before {
content: ""
}

.d-icon-volume:before {
content: ""
}

.d-icon-bars2:before {
content: ""
}

.d-icon-bars:before {
content: ""
}

.d-icon-phone:before {
content: ""
}

.d-icon-user:before {
content: ""
}

.d-icon-search:before {
content: ""
}

.d-icon-search2:before {
content: ""
}

.d-icon-bag:before {
content: ""
}

.d-icon-map:before {
content: ""
}

.d-icon-info:before {
content: ""
}

.d-icon-refresh:before {
content: ""
}

.d-icon-left-arrow:before {
content: ""
}

.d-icon-right-arrow:before {
content: ""
}

.d-icon-down-arrow:before {
content: ""
}

.d-icon-up-arrow:before {
content: ""
}

.d-icon-ruler:before {
content: ""
}

.d-icon-zoom:before {
content: ""
}

.d-icon-right-circle:before {
content: ""
}

.d-icon-left-circle:before {
content: ""
}

.d-icon-up-circle:before {
content: ""
}

.d-icon-down-circle:before {
content: ""
}

.d-icon-angle-right:before {
content: ""
}

.d-icon-angle-left:before {
content: ""
}

.d-icon-angle-up:before {
content: ""
}

.d-icon-angle-down:before {
content: ""
}

.d-icon-star:before {
content: ""
}

.d-icon-star-full:before {
content: ""
}

.d-icon-heart:before {
content: ""
}

.d-icon-heart-full:before {
content: ""
}

.d-icon-close:before {
content: ""
}

.d-icon-play-circle:before {
content: ""
}

.d-icon-home:before {
content: ""
}

.d-icon-filter-2:before {
content: ""
}

.d-icon-switch:before {
content: ""
}

.d-icon-switch-left-on:before {
content: ""
}

.d-icon-switch-right-on:before {
content: ""
}

.d-icon-filter-3:before {
content: ""
}

.d-icon-check:before {
content: ""
}

.d-icon-alert-solid:before {
content: ""
}

.d-icon-headphone:before {
content: ""
}

.d-icon-mode-grid:before {
content: ""
}

.d-icon-mode-list:before {
content: ""
}

.d-icon-cancel:before {
content: ""
}

.d-icon-instagram:before {
content: ""
}

.d-icon-solid-check:before {
content: ""
}

.d-icon-play-solid:before {
content: ""
}

.d-icon-mobile:before {
content: ""
}

.d-icon-minus:before {
content: ""
}

.d-icon-plus:before {
content: ""
}

.d-icon-wifi:before {
content: ""
}

.d-icon-times:before {
content: ""
}

.d-icon-times-circle:before {
content: ""
}

.d-icon-random:before {
content: ""
}

.d-icon-th-list:before {
content: ""
}

.d-icon-rocket:before {
content: ""
}

.d-icon-map-marker:before {
content: ""
}

.d-icon-birthday-cake:before {
content: ""
}

.d-icon-gift:before {
content: ""
}

.d-icon-female:before {
content: ""
}

.d-icon-clock:before {
content: ""
}

.d-icon-comments:before {
content: ""
}

.d-icon-rotate-left:before {
content: ""
}

.d-icon-rotate-right:before {
content: ""
}

.d-icon-reading:before {
content: ""
}

.d-icon-feeder:before {
content: ""
}

.d-icon-birthday-cake2:before {
content: ""
}

.d-icon-skirt:before {
content: ""
}

.d-icon-toy:before {
content: ""
}

.d-icon-butterfly:before {
content: ""
}
.d-icon-babycare2:before {
content: ""
}
.d-icon-butterfly2:before {
content: ""
}
.d-icon-dinner-set:before {
content: ""
}
.d-icon-ball2:before {
content: ""
}
.d-icon-apple:before {
content: ""
}
.d-icon-icecream:before {
content: ""
}
.d-icon-cake:before {
content: ""
}
.d-icon-meat:before {
content: ""
}
.d-icon-bread:before {
content: ""
}
.d-icon-cocktail:before {
content: ""
}
.d-icon-food:before {
content: ""
}
.d-icon-birthday-cake3:before {
content: ""
}
.d-icon-cash:before {
content: ""
}
.d-icon-service2:before {
content: ""
}
.d-icon-car:before {
content: ""
}
.d-icon-ball3:before {
content: ""
}
.d-icon-compare:before {
content: ""
}
.d-icon-jar:before {
content: ""
}
.d-icon-radish:before {
content: ""
}
.d-icon-arrow-down:before {
content: ""
}
.d-icon-arrow-left:before {
content: ""
}
.d-icon-arrow-right:before {
content: ""
}
.d-icon-arrow-up:before {
content: ""
}
.d-icon-earth:before {
content: ""
}
.d-icon-long-arrow-left:before {
content: ""
}
.d-icon-long-arrow-right:before {
content: ""
}
.d-icon-long-arrow-down:before {
content: ""
}
.d-icon-long-arrow-up:before {
content: ""
}
.d-icon-pill:before {
content: ""
}
.d-icon-cylinder:before {
content: ""
}
.d-icon-medical-bag:before {
content: ""
}
.d-icon-graph:before {
content: ""
}
/*==================================================*/
.step-by {
display: flex;
justify-content: center;
flex-wrap: wrap;
padding: 10px;
}
.step-by .title-step {
margin: 0 3.8rem 0 0rem;
margin-left: 1rem;
font-size: 12px;
text-transform: uppercase;
font-weight: 500 !important;
padding: .4rem 0;
position: relative;
}
.step-by .title-step:first-child {
margin-left: 0;
}
.step-by .title-step.active a {
color: var(--color-secondary) !important;
}
.title-simple {
display: block;
}
.step-by .title-step:not(:last-child)::after {
content: '\f054';
display: inline-block;
position: absolute;
right: -3.5rem;
top: 47%;
line-height: 0;
vertical-align: middle;
font-family: 'Font Awesome 5 Free';
font-weight: 700;
font-size: 0.8rem;
background-color: #fff;
color: #ccc;
}
.secure-img {
width: 125px;
}
/*==================================================*/
.cartPanel{
width: 100%;
position: relative;
background: var(--color-white);
border: 1px solid var(--color-gray-200);
border-radius: 5px;
}
.cartPanel .cartPanel-top{
display: flex;
align-items: center;
justify-content: space-between;
padding: 15px;
width: 100%;
position: relative;
}
.cartPanel .cartPanel-top .cptimebox{
display: flex;
align-items: center;
width: 49%;
border: 1px solid var(--color-gray-200);
border-radius: 5px;
height: 70px;
background: var(--color-white);
padding: 0px 15px;
}
.cptimebox.active{
border: 1px solid var(--color-secondary) !important;
background: #fffdf5 !important;
}
.cptimebox .cptimebox-icon{
width: 40px;
height: 40px;
overflow: hidden;
margin-right: 15px;
}
.cptimebox .cptimebox-icon img{
width: 40px;
height:40px;
object-fit: cover;
}
.cptimebox .cptimebox-text{
line-height: 20px;
}
.cartPanel .cartPanel-middle{
width:100%;
position: relative;
}
.carItembox{
width:100%;
position: relative;
}
.carItembox .carItembox-title{
display: flex;
align-items: center;
justify-content: space-between;
font-size: 14px;
background: var(--color-gray-200);
padding: 5px 15px;
font-weight: 600;
}
.carItembox .carItemboxbody{
width: 100%;
position: relative;
min-height: 500px;
}
.cItembSection{
width: 100%;
position: relative;
}
.cItembSection .alert{
font-size: 12px;
padding:5px 15px;
margin-bottom: 0px;
border: 0px !important;
border-radius: 0px;
}
.cItembSection .cItemb{
display: flex;
align-items: center;
width: 100%;
position: relative;
padding: 15px;
border-bottom: 1px solid var(--color-gray-100);
}
.cItembSection .cItemb.remove{
background: #f8d0d0;
}
.cItembSection .cItemb .cItemb-media{
width: 120px;
height: 120px;
overflow: hidden;
margin-right: 15px;
}
.cItemb .cItemb-media img{
width: 120px;
height: 120px;
object-fit: cover;
border-radius: 5px;
}
.cItemb .cItemb-content{
flex: 1;
}
.cItemb .Vdetails{
color:var(--color-info);
border: 1px solid var(--color-info);
border-radius: 5px;
padding: 0px 7px;
font-size: 10px;
display: inline-block;
line-height: 20px;
}
.cItemb-footer{
display: flex;
align-items: center;
justify-content: space-between;
margin-top: 20px;
width: 100%;
position: relative;
}
.cItemb .qty-changer {
display: flex;
align-items: center;
}
.cItemb .qty-changer button {
background: var(--color-white);
border: 1px solid var(--color-secondary);
border-radius: 3px;
width: 25px;
height: 25px;
display: flex;
align-items: center;
justify-content: center;
}
.cItemb .qty-changer .psbmiuns {
font-size: 9px !important;
height: 7px;
}
.cItemb .qty-changer .psbplus {
font-size: 9px !important;
height: 7px;
}
.cItemb .qty-changer span {
width: 30px;
height: 25px;
display: flex;
font-size: 16px;
font-weight: 600;
align-items: center;
justify-content: center;
}
.cItemb .cItemb-Itemremove{
cursor: pointer;
}
.cItemb .cItemb-Itemremove i{
font-size:16px;

}
.cItemb-add-more{
width: 50%;
height: 30px;
border-radius: 5px;
border: 1px dotted var(--color-extra01);
background: #f2fff1;
color: var(--color-extra01);
display: flex;
align-items: center;
justify-content: center;
margin: 20px auto 0px;
cursor: pointer;
font-size: 12px;
}
.cItemb-add-more i{
font-size: 10px;
margin-right: 5px;
}
.cItembPricelist{
width: 100%;
position: relative;
padding: 15px;
}
.cItembPricelist ul{
margin: 0;
padding: 0;
list-style: none;
}
.cItembPricelist ul li{
display: flex;
align-items: center;
height: 30px;
justify-content: space-between;
}
.cItembPricelist hr{
margin: 0;
border-top:1px dotted var(--color-gray-300);
}
.carItemboxfooter{
width: 100%;
display: flex;
align-items: center;
padding: 15px;
border-top: 1px solid var(--color-gray-200);
}
@media only screen and (max-width: 968px) {
.cartPanel{
border: 0;
border-radius: 0;
}
.cartPanel .cartPanel-top{
padding: 10px;
}
.cartPanel .cartPanel-top .cptimebox{
height: 55px;
padding: 0px 0px 0px 10px;
}
.cptimebox .cptimebox-icon{
width: 24px;
height: 24px;
overflow: hidden;
margin-right: 10px;
}
.cptimebox .cptimebox-icon img{
width: 24px;
height:24px;
object-fit: cover;
}
.cptimebox .cptimebox-text {
line-height: 14px;
}
.cptimebox-text h6{
font-size: 12px;
}
.cptimebox-text p{
font-size: 9px;
}
.cItembSection .cItemb .cItemb-media {
width: 90px;
height: 90px;
overflow: hidden;
margin-right: 15px;
}
.cItemb .cItemb-media img {
width: 90px;
height: 90px;
object-fit: cover;
border-radius: 5px;
}
.cItemb-content h6{
font-size: 12px;
}
.cItemb-content p{
font-size: 11px;
}
}
/*==================================================*/
.addreesPanel{
width: 100%;
position: relative;
border: 1px solid var(--color-gray-200);
border-radius: 5px;
}
.addreesPanel .addreesPanel-header{
padding: 15px;
width: 100%;
position: relative;
border-bottom: 3px solid var(--color-gray-200);
}
.addreesPanel-body{
padding: 15px;
width: 100%;
position: relative;
min-height: 600px;
}
.addreesPanel-footer{
padding: 15px;
width: 100%;
position: relative;
border-top: 1px solid var(--color-gray-200);
}

.addressbox{
width: 100%;
position: relative;
border: 1px solid var(--color-gray-200);
background: var(--color-gray-100);
margin-top: 10px;
padding: 15px 15px 15px 45px;
border-radius: 5px;
line-height: 20px;
}
.addressbox.active{
border: 1px solid var(--color-secondary);
}
.save-address-check {
background: #f5f5f5;
border: 2px solid #ddd;
border-radius: 50%;
height: 16px;
left: 15px;
position: absolute;
top: 15px;
width: 16px;
}
.addressbox.active .save-address-check {
background: var(--color-secondary);
}
@media only screen and (max-width: 968px) {
.addreesPanel{
border: 0px;
}
}
/*==================================================*/
.checkoutPanel {
width: 100%;
position: relative;
border: 1px solid var(--color-gray-200);
border-radius: 5px;
}
.checkoutPanel-address{
width: 100%;
position: relative;
border-bottom: 1px solid var(--color-gray-200);
padding: 20px;
line-height: 24px;
}
.checkoutPanel-card{
width: 100%;
position: relative;
border-top: 1px solid var(--color-gray-200);
padding: 20px;
line-height: 24px;
}
.checkoutPanel-card input{
width: 100%;
height: 50px;
border-radius: 4px !important;
font-size: 13px;
}
.changeaddress{
position: absolute;
right: 15px;
top:15px;
color: var(--color-primary);
cursor: pointer;
}
.checkoutPanel-ordernote{
width: 100%;
position: relative;
border-bottom: 1px solid var(--color-gray-200);
padding: 20px; 
}
.checkoutPanel-ordernote label{
font-size: 15px;
font-weight: 700;
margin-bottom: 5px;
}
.checkoutPanel-ordernote textarea{
width: 100%;
height: 100px;
border: 1px solid var(--color-gray-200);
border-radius: 5px;
}
.checkoutOrdersummary{
width: 100%;
position: relative;
}
.checkoutOrdersummary-header{
display: flex;
align-items: center;
height: 60px;
justify-content: space-between;
padding: 0px 20px;
cursor: pointer;
}
.checkoutOrdersummary-header i{
font-size: 22px;
}
.checkoutOrdersummary-content{
width: 100%;
position: relative;
border-top: 1px solid var(--color-gray-200);
}
.cartsummary{
width: 100%;
position: relative;
padding: 20px;
border-top: 1px solid var(--color-gray-200);
}
.cartsummary ul{
margin: 0;
padding: 0;
list-style: none;
}
.cartsummary ul li{
display:flex;
align-items: center;
justify-content: space-between;
height: 30px;
}
.cartsummary-footer{
padding: 20px;
width: 100%;
position: relative;
border-top: 3px solid var(--color-gray-200);
}
@media only screen and (max-width: 968px) {
.checkoutPanel {
width: 100%;
position: relative;
border: 0px solid var(--color-gray-200);
border-radius: 5px;
}
.checkoutPanel-address {
width: 100%;
position: relative;
border-bottom: 3px solid var(--color-gray-200);
padding:15px;
line-height: 24px;
}
.checkoutPanel-ordernote {
width: 100%;
position: relative;
border-bottom: 3px solid var(--color-gray-200);
padding: 15px;
}
.checkoutOrdersummary-header {
display: flex;
align-items: center;
height: auto;
justify-content: space-between;
padding: 18px 15px 15px;
cursor: pointer;
}
.checkoutOrdersummary-header h6{
font-size: 14px;
}
.checkoutOrdersummary-header i {
font-size: 18px;
}
.cartsummary {
width: 100%;
position: relative;
padding: 15px;
border-top: 3px solid var(--color-gray-200);
}
.checkoutPanel-card{
width: 100%;
position: relative;
border-top: 3px solid var(--color-gray-200);
padding: 15px;
line-height: 24px;
}
}
/*==================================================*/
/*==================================================*/
.parentDisable {
position: fixed;
top: 0;
left: 0;
opacity: 0.8;
z-index: 998;
height: 100%;
width: 100%;
}
.overlay-box {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
color: white;
opacity: .8;
z-index: 1000;
}
/*==================================================*/
.pac-container{
z-index: 99999999999999999999 !important;
}
/*=====================================================*/
.order-box{
position: relative;
background: var(--color-white);
width: 100%;
display: flex;
border-radius: 5px;
margin-bottom: 20px;
border: 1px solid var(--color-gray-200);
}
.order-box .info {
position: relative;
width: 75%;
border-right: 1px dashed #e3e3e3;
padding: 15px;
}
.order-box .bcode {
position: relative;
width: 25%;
padding: 25px;
text-align: center;
}
.order-box .orderid-box {
background:var(--color-gray-100);
border-radius:5px;
padding: 15px 10px 10px 10px;
}
.morderbox{
width:100%;
position: relative;
background: var(--color-white);
display: inline-block;
border-bottom: 3px solid var(--color-gray-200);

}
.morderbox .morderbox-body{
padding: 15px;
}
.morderbox .morderbox-footer{
display: flex;
align-items: center;
justify-content: space-between;
padding: 5px 15px 15px;
}
.morderbox-link{
font-size: 12px;
}
.morderbox-status{
font-size: 12px;
padding: 5px 10px;
border-radius: 5px;
}
.bg-light-yellow{
background: #f8b81f;
}
/*=====================================================*/
.appsection{
width: 100%;
position: relative;
background: #2b1e16;
color: #fff;
border-radius: 10px;
}
.appsection .appsection-content{
padding: 100px;
position: relative;
z-index: 99999;
}
.appsection .appsection-content h2{
line-height: 52px;
font-size: 42px;
}
.appsection .appsection-content h6{
line-height: 32px;
}
.appsection-button{
width: 100%;
position: relative;
display: flex;
align-items: center;
margin-top: 30px;
}
.appsection-button a{
width: 35%;
margin-right: 15px;
}
.appsection-button img{
width: 100%;
}
.appsection:after{
position: absolute;
top:0;
right: 0;
background: url(../public/img/app.png) no-repeat;
width: 100%;
height: 100%;
background-position: 90%;
background-size: 40%;
content: '';
}
/*=====================================================*/
.contact-address-section {
background: #fff;
border: 1px solid #ececec;
border-radius: 5px;
margin-top: 0;
padding: 25px;
}
.address-contact{
width:100%;
position:relative;
}
.address-contact ul{
margin:0;
padding:0;
list-style:none;
}
.address-contact ul li{
width:100%;
display:inline-block;
position:relative;
margin-top:10px;
}
.address-contact ul li a{
width: 100%;
display:flex;
color:#333;
align-items:center;
line-height: 24px;
}
.address-contact ul li a span{
flex:1;
margin-left:10px;
}
.address-contact ul li i{
position:relative;
font-size: 14px;
border: 1px solid var(--color-primary);
width: 30px;
height: 30px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
border-radius:50%;
background:var(--color-primary);
color:#fff;
}
.address-contact ul li a:hover{
color:var(--color-primary) !important;
}
/*=====================================================*/
.quickviewmodal .modal-dialog {
max-width: 1200px;
}
/*=====================================================*/
.maddressbox{
width:100%;
position: relative;
background:#fff;
display: inline-block;
border-bottom:1px solid #ddd;
}
.maddressbox .maddressbox-body{
padding: 15px 75px 15px 15px;
}
.maddressbox .maddressbox-footer{
display: flex;
align-items: center;
padding: 5px 15px 15px;
}
.maddressbox .maddressbox-footer a{
margin-right: 20px;
color:#6d1a17;
font-weight: 600;
}
.maddress-header{
padding: 10px 15px;
width:100%;
font-weight: 600;
background:var(--color-gray);
}
.maddressbox-type{
position: absolute;
top: 15px;
right: 15px;
background:var(--color-gray);
padding: 2px 10px;
border-radius: 10px;
text-transform: uppercase;
font-size: 11px;
font-weight: 600;
}
.maddress-footer{
position:fixed;
bottom:0;
left:0;
padding:15px;
width:100%;
border-top:1px solid #eee;;
}
/*=====================================================*/
.tiffindetails{
width: 100%;
position: relative;
}
.tiffindetails ul{
margin: 0;
padding: 0;
list-style: none;
}
/*=====================================================*/
.applyCoupons{
width: 100%;
position: relative;
height: 60px;
display: flex;
align-items: center;
justify-content: space-between;
border-bottom: 1px solid var(--color-gray-200);
border-top: 1px solid var(--color-gray-200);
padding: 0px 20px;
cursor: pointer;
}
/*==========================================================*/
.couponModal .modal-dialog{
max-width: 450px;
}
.couponModal .couponModal-header{
padding:25px 25px 10px 25px;
width:100%;
position:relative;
color:#333;
}
.couponModal .couponModal-search{
width:100%;
position:relative;
padding:0px 25px 10px;
}
.couponModal-search input{
width:100%;
height:45px;
border:1px solid #ddd;
border-radius:5px;
padding:0px 15px;
}
.couponModal .couponModal-search button{
position: absolute;
top: 0;
right: 26px;
height: 43px;
background: transparent;
border: 0px;
width: 75px;
}
.apply-coupons-list{
width:100%;
position:relative;
color:#333;
}
.apply-coupons-list ul{
margin:0;
padding:0;
list-style:none;
}
.apply-coupons-list ul li{
display:inline-block;
width:100%;
position:relative;
padding:0px 25px 10px;
}
.aclbox{
background:#f5f5f5;
padding:15px;
border-radius:5px;
position:relative;
width:100%;
}
.aclbox .aclbox-coupon{
border: 1px dashed var(--color-primary) !important;
display:inline-block;
padding:3px 10px;
border-radius:5px;
margin-bottom:10px;
font-weight:600;
}
.aclbox .aclbox-apply{
position:absolute;
top:15px;
right:15px;
color:var(--color-primary);
cursor:pointer;
}
/*=====================================================*/
.cardselect{
padding: 15px;
width: 100%;
position: relative;
}
.cardselect .cardselect-inner{
display: flex;
align-items: center;
justify-content: space-between;
margin-top: 20px;
}
.cardselect .cardselectbox{
width: 100%;
position: relative;
border: 1px solid #ddd;
height: 70px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 5px;
cursor: pointer;
}
.cardselectbox ul{
margin: 0;
padding: 0;
list-style: none;
display: flex;
align-items: center;
}
.cardselectbox ul li{
margin: 0px 5px;
}
.cardselectbox.active{
border: 1px solid var(--color-extra01);
background: #f9fffc;
}
.cardsection{
width: 100%;
position: relative;
padding: 15px;
}
.carddetal{

}
.cardsection label{
font-weight: 600;
margin-bottom: 5px;
}
.cardsection input{
width: 100%;
position: relative;
height: 45px;
border: 1px solid #ddd;
border-radius: 4px;
padding: 15px;
}
.crdform{
width: 100%;
position: relative;
}
.crdform-icon{
padding-right: 80px;
}
.cicon{
position: absolute;
right: 0;
top:28px;
}
.checkboxinput{
display: flex;
align-items: center;
}
.checkboxinput input[type="checkbox"]{
width: 20px;
height: 20px;
margin-right: 10px;
}
.cardsavebox{
  width: 100%;
  position: relative;
  margin-bottom: 15px;
}
.cardsavebox .cardsaveboxnu{
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  cursor: pointer;
}
.cardsaveboxnu.active{
  border: 1px solid var(--color-extra01);
}
.cardsaveboxselect{
  display: none;
}
.cardsaveboxnu.active .cardsaveboxselect{
  display: block;
  margin-left: auto;
  width: 24px;
}
.cardsaveboxarrow{
  width: 24px;
  margin-left: auto;
}
.cardsavebox .cardsavebox-icon{
width: 50px;
height: 50px;
margin-right: 10px;
}
.cardsavebox-number{
  font-size: 16px;
  font-weight: 600;
}
.cardsavebox-cvv{
  display: flex;
  width: 30%;
  margin-top: 10px;
}
.cardsavebox-cvv input{
  margin-right: 10px;
}
.cardwallet{
  width: 100%;
  position: relative;
  height: 80px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
}
.cardwallet.active{
  border: 1px solid var(--color-extra01);
  background: #f9fffc;
}
.cardwallet-price{
  font-size: 16px;
  font-weight: 600;
  margin-right: 15px;
  border-right: 1px solid #ddd;
  padding-right: 15px;
}
.cardselectleft{
  margin-left: auto;
}
/*=====================================================*/
/*=====================================================*/
/*=====================================================*/
/*=====================================================*/
/*=====================================================*/
/*=====================================================*/